<script>
export default {
  name: 'NewEvent',
  props: {
    user: [Object, String],
  },
  data() {
    return {}
  },
  computed: {
    loggedIn() {
      return this.user
    },
  },
}
</script>

<style scoped lang="scss"></style>
